import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent {

  public isBluebird: boolean = false;

  ngOnInit(){
    if (environment.blueBird){
      this.isBluebird = true;
    }
  }
}
