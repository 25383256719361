import { ActionHistory } from "../Audits/action-history";
import { AuditLocation } from "../Locations/audit-location";
import { ActionResponse } from "./action-response";

export class Action{
    public AuditResultDetailActionID:number = -1;
    public ActionDescription:string = "";
    public Reference:string ="";
    public ActionDeadline:Date = new Date();
    public ActionAssignee:string = "";
    public ActionOwnerTypeID:number = -1;
    public Result:string = "";
    public Comments:string = "";
    public QuestionHeader:string = "";
    public QuestionBody:string = "";
    public QuestionHelpText:string = "";
    public QuestionIdentifiers:string = "";
    public AuditText:string = "";
    public AuditLocation:AuditLocation = new AuditLocation();
    public DateRecorded:Date = new Date();
    public ActionResponseList:ActionResponse[]=[];
    public Resolved:boolean = false;
    public ResolvedDate:Date = new Date();
    public ResolvedUser:string = "";
    public ActionCreator:string = "";
    public ActionOwnerID:number = -1;
    public NewNote:string = "";
    public History:ActionHistory[] = [];
    constructor(values: Object = {}){
        Object.assign(this, values);
        if(values.hasOwnProperty('ActionResponseList')){
            this.ActionResponseList = [];
            ((values as Action)['ActionResponseList'] as ActionResponse[]).forEach(element => {
                this.ActionResponseList.push(new ActionResponse(element));
            });
        }
        if(values.hasOwnProperty('AuditLocation')){
            this.AuditLocation = new AuditLocation((values as Action)['AuditLocation'] as AuditLocation);
        }
        if(values.hasOwnProperty('History')){
            this.History = [];
            ((values as Action)['History'] as ActionHistory[]).forEach(element => {
                this.History.push(new ActionHistory(element));
            });
         } 
    }
}