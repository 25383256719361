import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.scss']
})
export class SecureComponent {

  public menuIsOpen: boolean = false;
  public isBluebird: boolean = false;
  constructor() { }

  ngOnInit(): void {
    if (environment.blueBird){
      this.isBluebird = true;
    }
  }

  menuOpen(){
    this.menuIsOpen = true;
  }

  menuClosed(){
    this.menuIsOpen = false;
  }
}
