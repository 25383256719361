<div>
    <div>
        <label for="inActionDescription"><strong>Action Description:</strong></label>
        <textarea #actionDescription [(ngModel)]="newAction.ActionDescription"
            class="text-box lg:w-2/5 mx-2"></textarea>
        <div class="mt-2">
        </div>
        <div>
            <label for="inActionAssignee"><strong>Assigned To:</strong></label>
            <select class="text-box mx-2 lg:w-2/5" name="inActionAssignee" [(ngModel)]="currentUser"
                [compareWith]="userComparison">
                <option *ngFor="let a of _actionUsers" [ngValue]="a" [disabled]="a.UserID == -1"
                    [ngClass]="{'font-bold':a.UserTypeID==2}">{{a.DisplayName}}<span *ngIf="!a.Valid">*</span>
                </option>
            </select>
        </div>
        <div>
            <label>
                <strong>Action Due Date:</strong>
                <input [owlDateTime]="dt2" class="text-box lg:w-2/5 mx-2" [(ngModel)]="newAction.ActionDeadline"
                    [owlDateTimeTrigger]="dt2" />
                <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
            </label>
        </div>
        <div *ngIf="showWarning" class="text-red-600">Action Description and Valid Assignee Required To Save</div>
        <div class="mx-2 flex lg:w-2/5 justify-end mt-4">
            <button class="btn-inverse mr-2" (click)="resetAction()">Cancel</button>
            <button (click)="saveAction()" class="btn-primary">Add Action</button>
        </div>

    </div>