import { Component } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { UtilService } from '../core-module/services/util.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Care Audits';

  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];
  public favIcon: HTMLLinkElement = document.querySelector('#appIcon')!;

  constructor(public utilService: UtilService, private titleService: Title) {}

  ngOnInit(){
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');
    this.subscriptions.push(this.onlineEvent.subscribe(event => {this.utilService.isOnline = true}));
    this.subscriptions.push(this.offlineEvent.subscribe(event => {this.utilService.isOnline = false;}));
    this.utilService.isOnline = navigator.onLine;
    this.titleService.setTitle("Care Audits");
    if (environment.blueBird){
      this.titleService.setTitle("Bluebird Care");
      this.favIcon.href = "/bbfavicon.ico";
    } else {
      this.favIcon.href = "/favicon.ico";
    }
  }

  ngOnDestroy(): void{
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
