import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, catchError, of, tap } from 'rxjs';
import { LoginResponse } from 'src/app/core-module/models/login-response';
import { AuthService } from 'src/app/core-module/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  signInAllowed:boolean = true;
  errorText:string = "";
  username:string = "";
  password:string = "";
  public showForgotPassword: boolean = false;
  public email: string = "";
  public resetClicked: boolean = false;
  public resetEmailSent: boolean = false;
  public resetErrorMessage: boolean = false;
  public isBluebird: boolean = false;

  @ViewChild("usernameElement") usernameElement!: ElementRef;

  constructor(public authservice: AuthService, public router: Router) { }

  ngOnInit(){
    if (environment.blueBird){
      this.isBluebird = true;
    }
  }

  checkLogin(event: KeyboardEvent){
    if (event.key == "Enter"){
      this.doLogin();
    }
  }

  login():void{
    this.doLogin();
  }

  private doLogin(): void{
    this.errorText = "";
    this.signInAllowed = false;
    this.authservice.login(this.username,this.password).pipe(
      tap(res => {
        if (!res.body?.IsError){
          this.router.navigate(['home']);
        } else {
          this.resetOnError();
        }
        this.signInAllowed = true;
      }),
      catchError(err => {
        this.resetOnError();
        return of(new LoginResponse());
      })
    ).subscribe();
  }

  private resetOnError(): void{
    this.username = "";
    this.password = "";
    this.usernameElement.nativeElement.focus();
    this.errorText = "Username/password not recognized.";
    this.signInAllowed = true;
  }

  public showForgot(){
    this.showForgotPassword = true;
  }

  public showLogin(){
    this.showForgotPassword = false;
    this.resetErrorMessage = false;
    this.resetClicked = false;
    this.resetEmailSent = false;
  }

  public resetPassword(){
    this.resetClicked = true;
    this.resetErrorMessage = false;
    this.resetEmailSent = false;

    this.authservice.resetPassword(this.email).pipe(
      tap(res =>
        {
          if (!res.body!.IsError){
            this.resetEmailSent = true;
            this.email = '';
          } else {
            this.resetErrorMessage = true;    
          }
          this.resetClicked = false;
        }
      ),
      catchError((err, n) => {
        this.resetErrorMessage = true;    
        this.resetClicked = false;
        return EMPTY;
      })
    ).subscribe();

    
  }

}
