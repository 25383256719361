<div class="md:h-[100vh] h-[calc(100vh-3rem-env(safe-area-inset-top))] w-full absolute">
    <div class="h-12 bg-green-700 flex items-center lg:hidden">
        <span class="material-symbols-outlined text-offwhite-500 text-4xl pl-2 cursor-pointer flex-none w-10" id="btnMobileMenu" (click)="menuOpen()">menu</span>
        <div class="flex justify-center grow py-2">
            <img src="/assets/logowhite.png" *ngIf="!isBluebird" class="h-12 py-2"/>
            <img src="/assets/bluebirdlogowhite.png" *ngIf="isBluebird" class="h-12 py-2"/>
        </div>
        <span class="w-10 flex-none"></span>
    </div>
    <div class="flex">
        <app-menu [menuIsOpen]="menuIsOpen" (menuHasBeenClosed)="menuClosed()"></app-menu>
        <div class="flex-grow xl:h-screen">
            <div class="md:h-[100vh] h-[calc(100vh-3rem-env(safe-area-inset-top))]  overflow-y-auto">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
<app-toastr-holder></app-toastr-holder>