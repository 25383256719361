import { BasicUser } from "../basic-user";
import { ActionHistory } from "./action-history";
import { StandardQuestionEntry } from "./standard-question-entry";

export class ComplianceAction{
    public ActionID: number = -1;
    public ActionOwner: BasicUser = new BasicUser();
    public ActionOwnerTypeID: number;
    public ReportingUserID: number;
    public StandardAction:StandardQuestionEntry =  new StandardQuestionEntry();
    public ActionNote: string = "";
    public Display:boolean = false;
    //public AdditionalUserIDs: number[];
    //public AdditionalPeopleInvolved: string;
    public ActionDeadline: Date = new Date();
    public Editing:boolean = false;
    constructor(values: Object = {}){
        Object.assign(this, values);   
        if(values.hasOwnProperty('ActionOwner')){
            this.ActionOwner = new BasicUser((values as ComplianceAction)['ActionOwner'] as BasicUser) 
         }    
         if(values.hasOwnProperty('StandardAction')){
            this.StandardAction = new StandardQuestionEntry((values as ComplianceAction)['StandardAction'] as StandardQuestionEntry)
         } 

    }
}