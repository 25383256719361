<div class="container">
    <div class="px-4 mt-2">
        <button class="btn-inverse py-1" (click)="goBack()">< Back</button>
    </div>
    
    <div class="border-solid border-2 border-grey-500 mb-1 mt-3 mx-4"></div>
	

    <h1 class="lg:p-8 lg:pb-4 p-2" *ngIf="!isGroup">User Locations</h1>
    <h1 class="lg:p-8 lg:pb-4 p-2" *ngIf="isGroup">Group Locations</h1>
    <div class="lg:ml-8 p-2">
        <div class="p-4" *ngIf="showLocation">
            <div *ngIf="!topLevel && !isLocationSelected" (click)="resetLocations()" class="hover:cursor-pointer flex">
                <span
                    class="text-center material-symbols-outlined text-green-700 self-center mr-1 ">undo</span><span>Reset
                    Locations</span>
            </div>
            <div *ngIf="locationsReady && !isLocationSelected">
                <div *ngFor="let l of possibleLocations" class="flex w-full my-2">
                    <span
                        class="p-2 md:p-4 flex-grow standardTextSize border-spacing-1.5 border-b-grey-500 border-s-2  flex justify-between align-middle "
                        [ngClass]="{'selected': l.LocationID == selectedLocation.LocationID, 'hover:hover-for-select ':l.HasPermission, 'bg-grey-100 hover:cursor-not-allowed':!l.HasPermission, 'bg-grey-300':l.HasPermission&& l.LocationID != selectedLocation.LocationID}"
                        (click)="selectLocation(l)">{{l.LocationName}}</span>
                    <div *ngIf="locationHasChildren(l)" class=" bg-standardwhite "
                        (click)="buildLocationDDL(l.LocationID)">
                        <span
                            class="standardTextSize text-offblack hover:hover-for-select bg-grey-300 border-grey-900 border-spacing-2 border-s h-full px-4 flex items-center">Sub
                            Locations</span>
                    </div>
                </div>
            </div>
            <div  *ngIf="isLocationSelected">
                <button class="btn-inverse" (click)="resetSelectLocation()">Select Location</button>
            </div>

        </div>
        <div class="flex-col mx-4 mt-4" *ngIf="isLocationSelected">
            <div class="flex  items-baseline mb-4 flex-col">

                <p class="font-bold mr-4 text-green-700 text-xl">{{loadedLocation.LocationName}}</p>
            </div>
            <div class="mt-2 border-solid ">
                <table class="xl:w-1/5 lg:w-2/5 my-4 w-full">
                    <thead>
                        <tr class="pb-3">
                            <th class="text-left">Name</th>
                            <th class="text-center">Location Permitted</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let u of completeUserList">
                            <td>
                                {{u.DisplayName}}
                            </td>
                            <td class="flex justify-center py-2">
                                <app-toggle-switch [(checked)]="u.Selected"></app-toggle-switch>
                            </td>
                        </tr>

                    </tbody>
                </table>
                <button class="btn-primary mr-4" (click)="save()">Save</button>
                <button class="btn-warning" (click)="reset()">Undo</button>
            </div>
        </div>
    </div>
</div>