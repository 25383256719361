import { Component } from '@angular/core';
import { tap } from 'rxjs';
import { Audit } from 'src/app/core-module/models/Audits/audit';
import { AuditCollectionResult } from 'src/app/core-module/models/Audits/audit-collection-result';
import { AuditCompletionInfo } from 'src/app/core-module/models/Audits/audit-completion-info';
import { AuditResultDetail } from 'src/app/core-module/models/Audits/AuditResults/audit-result-detail';
import { AuditSummaryResultRequest } from 'src/app/core-module/models/Audits/AuditResults/audit-summary-result-request';
import { DDL } from 'src/app/core-module/models/ddl';
import { AuditLocation } from 'src/app/core-module/models/Locations/audit-location';
import { LocationResult } from 'src/app/core-module/models/Locations/location-result';
import { AuditResultsService } from 'src/app/core-module/services/audit-results.service';
import { AuditService } from 'src/app/core-module/services/audit.service';
import { ReferenceService } from 'src/app/core-module/services/reference.service';

@Component({
  selector: 'app-results-home',
  templateUrl: './results-home.component.html',
  styleUrl: './results-home.component.scss'
})
export class ResultsHomeComponent {

  public auditResultList:Audit[] = [];
  public auditResultDetail:AuditResultDetail = new AuditResultDetail;
  public startDate:Date = new Date();
  public endDate:Date = new Date();

  public returnedLocations:AuditLocation[] = [];
  public locations:DDL[] = [];
  public selectedLocationDDL: DDL = new DDL();

  public returnedAudits:AuditCollectionResult = new AuditCollectionResult;
  public auditDDLList:DDL[] = [];
  public selectedAuditDDL: DDL = new DDL();

  public showFilters:boolean = true;
  public showList:boolean = true;
  
  constructor(private auditResults:AuditResultsService, private refService:ReferenceService, private auditService:AuditService){
    this.startDate = new Date(new Date().setDate(this.startDate.getDate() - 30))
    this.getAuditList();
    this.getValidLocation();
    this.getAuditFilter();
  }
  buildLocations(){
    this.locations = [];
    this.locations.push(new DDL({ID:-1,Description:"All Locations"}));
    this.returnedLocations.forEach(e=>{
      this.locations.push(new DDL({ID:e.LocationID,Description:e.LocationName}));      
    })
    this.selectedLocationDDL = this.locations[0];
  }
  getValidLocation(){
    this.refService.getFlatLocationsForUser().pipe(
      tap(res =>{
        if(res!=null){
          this.returnedLocations = res
          this.buildLocations();
        }
      })
    ).subscribe();
  }
  getAuditFilter(){
    this.auditService.getAudits().pipe(
      tap(res =>{
        if(res!=null){
          this.returnedAudits = res
          this.buildAuditFilter();
        }
      })
    ).subscribe();
  }
  buildAuditFilter(){
    this.auditDDLList = [];
    this.auditDDLList.push(new DDL({ID:-1,Description:'All'}));
    this.returnedAudits.Audits.forEach(e=>{
      this.auditDDLList.push(new DDL({ID:e.AuditID, Description:e.AuditName}));
    })
    this.auditDDLList = this.auditDDLList.sort((a, b) => {
      if (a.Description > b.Description) {
        return 1;
      }
      if (a.Description < b.Description) {
        return -1;
      }
      return 0;
    })
    this.selectedAuditDDL = this.auditDDLList[0];
  }
  getAuditList(){
    var auditResultRequest = new AuditSummaryResultRequest({hasStart:true,StartDate:this.startDate,hasEnd:true,EndDate:this.endDate});
    if(this.selectedAuditDDL.ID!=-1){
      auditResultRequest.AuditID = this.selectedAuditDDL.ID!
    }
    if(this.selectedLocationDDL.ID!=-1){
      auditResultRequest.LocationID = this.selectedLocationDDL.ID!
    }

    this.auditResults.getAuditResultList(auditResultRequest).pipe(
      tap(res =>{
        if(res!=null){
          this.auditResultList = res
        }
      })
    ).subscribe();
  }
  getAudit(AuditResultID:any){
    this.auditResultDetail.AuditName = "Loading...";
    this.auditResults.getAuditResultDetail(AuditResultID).pipe(
      tap(res =>{
        if(res!=null){          
          this.auditResultDetail = res;
        }
      })
    ).subscribe();
    this.showDetail();
    //change panel
  }
  showDetail(){
    this.showList = false;
  }
  unhideFilters(){
    this.showFilters = true;
  }
  unhideList(){
    this.showList = true;
  }
  refreshSearch(){
    this.getAuditList();
    //this.showFilters =false;
  }

  
  
}
