import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, of } from 'rxjs';
import { AuthService } from 'src/app/core-module/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @Input() public menuIsOpen: boolean = false;
  @Output() menuHasBeenClosed = new EventEmitter<void>();
  public isBluebird: boolean = false;

  constructor(public authService: AuthService, public router: Router){}

  ngOnInit(){
    if (environment.blueBird){
      this.isBluebird = true;
    }
  }

  async signout(){
    await lastValueFrom(of(this.authService.signout()));
    this.router.navigate(['login']);
  }

  menuOpen(){
    this.menuIsOpen = true;
  }

  closeMenu(){
    this.menuIsOpen = false;
    this.menuHasBeenClosed.emit();
  }
  startAudit(){
    this.router.navigateByUrl('startaudit',{state: {"AuditID" : -1,"WardID":-1}})
    this.closeMenu();
  }

}
